import { ESETPresetupObject } from '../../models/ESETPresetupObject';
import { ESETUpdateCredentials } from '../../models/ESETUpdateCredentials';
import { ESETCreateCustomerDTO } from '../../models/ESETCreateCompanyDTO';
import { IPartner } from 'src/modules/partner/models/partner.model';

export class MakePartnerESETReseller {
  static readonly type = '[ESET] MakePartnerESETReseller';
  constructor(public payload: { partnerId: string; signupEmail: string }) {}
}

export class GetPartnerCustomers {
  static readonly type = '[ESET] GetPartnerCustomers';
  constructor(public payload: { partnerId: IPartner['id'] }) {}
}

export class CreatePartnerCustomer {
  static readonly type = '[ESET] CreatePartnerCustomer';
  constructor(public payload: { esetCompanyDTO: ESETCreateCustomerDTO }) {}
}

export class SaveESETPartnerCredentials {
  static readonly type = '[ESET] SaveESETPartnerCredentials';
  constructor(
    public payload: {
      esetPartnerCredentials: ESETPresetupObject;
      partnerId: string;
    },
  ) {}
}

export class CheckPartnerCredentials {
  static readonly type = '[ESET] CheckPartnerCredentials';
  constructor(public payload: { partnerId: string }) {}
}

export class AcceptTerms{
  static readonly type = '[ESET] AcceptTerms';
  constructor(public payload: { partnerId: string }) {}

}

export class UpdatePartnerCredentials {
  static readonly type = '[ESET] UpdatePartnerCredentials';
  constructor(
    public payload: {
      updatedPartnerCredentials: ESETUpdateCredentials;
      partnerId: string;
    },
  ) {}
}

export class GetPartnerMigrationStatus {
  static readonly type = '[ESET] GetPartnerMigrationStatus';
  constructor(public payload: { partnerId: string }) {}
}
