import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ESETPresetupObject } from '../models/ESETPresetupObject';
import { ESETUpdateCredentials } from '../models/ESETUpdateCredentials';
import { ESETMigrationObject } from '../models/ESETMigrationObject';

import { ESETConfirmMigration } from '../models/ESETConfirmMigration';
import { ESETCreatePartnerReseller } from '../models/ESETCreatePartnerReseller';
import { ESETCustomer } from '../models/ESETCustomer';
import { ESETConnectExcistingMSP } from '../models/ESETConnectExcistingMSP';
import { ESETCreateCustomerDTO } from '../models/ESETCreateCompanyDTO';
import { IPartner } from 'src/modules/partner/models/partner.model';

@Injectable({
  providedIn: 'root',
})
export class ESETPartnerService {
  private readonly ESETPartnerApi: string = environment.ESETApiUrl + '/partners/';
  private readonly ESETCustomerApi: string = environment.ESETApiUrl + '/customer/';

  constructor(private http: HttpClient) {}

  //* Done
  makePartnerESETReseller(createPartnerReseller: ESETCreatePartnerReseller): Observable<string> {
    return this.http.post<string>(this.ESETPartnerApi + 'Create/New', createPartnerReseller);
  }

  //* Done
  getPartnerCustomers(partnerId: IPartner['id']): Observable<ESETCustomer[]> {
    return this.http.get<ESETCustomer[]>(this.ESETPartnerApi + partnerId + '/CompanyChildren');
  }

  //* Done
  createPartnerCustomer(esetCompanyDTO: ESETCreateCustomerDTO): Observable<any> {
    return this.http.post<string>(this.ESETCustomerApi, esetCompanyDTO);
  }

  //* Done
  savePartnerCredentials(loginInfo: ESETPresetupObject, partnerId: string): Observable<any> {
    return this.http.post<string>(this.ESETPartnerApi + partnerId + '/Init', loginInfo);
  }

  //* Done
  checkPartnerCredentials(partnerId: string): Observable<boolean> {
    return this.http.get<boolean>(this.ESETPartnerApi + partnerId + '/CheckCredentials/');
  }

  //* Done
  updatePartnerCredentials(updatedPartnerCredentials: ESETUpdateCredentials, partnerId: string): Observable<any> {
    return this.http.put(this.ESETPartnerApi + partnerId + '/Credentials/', updatedPartnerCredentials);
  }

  //* Done
  connectExcistingMSPAccount(connectExcistingMSPAccount: ESETConnectExcistingMSP, partnerId: string): Observable<any> {
    return this.http.post(this.ESETPartnerApi + partnerId + '/AttachExistingESETAccount', connectExcistingMSPAccount);
  }

  //* Done
  getPartnerMigrationStatus(partnerId: string): Observable<ESETMigrationObject> {
    return this.http.get<ESETMigrationObject>(this.ESETPartnerApi + 'Migration/' + partnerId + '/Status/');
  }

  //* Done
  cancelMigrationRequest(partnerId: string) {
    return this.http.delete(this.ESETPartnerApi + 'Migrations/' + partnerId);
  }

  //*Done
  userVerificatingMigration(partnerId: string) {
    return this.http.patch(this.ESETPartnerApi + 'Migrations/' + partnerId + '/Confirm', {});
  }

  //*Done
  confirmMoveOfPartnerESETCompanyToCloudFactory(confirmMoveObject: ESETConfirmMigration) {
    return this.http.put(this.ESETPartnerApi + 'ConfirmMoveOfPartnerESETCompanyToCloudFactory/', confirmMoveObject);
  }

  //*Done
  getPartnerMigrationDetails(partnerId: string): Observable<ESETMigrationObject> {
    return this.http.get<ESETMigrationObject>(this.ESETPartnerApi + 'Migration/' + partnerId + '/Details');
  }

  checkBCESETRelation(partnerId: string): Observable<boolean> {
    return this.http.get<boolean>(this.ESETPartnerApi + partnerId + '/relation');
  }

  acceptTerms(partnerId: string) {
    return this.http.post(this.ESETPartnerApi + partnerId + '/Terms', {})
  }
}
